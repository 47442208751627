import logo from './images/logo.webp';
import packet from './images/packet.webp'
import packetIcon from './images/bag_in_box.webp'
import apple from './images/apple.webp'
import priceTag from './images/price_tag_usd.webp'
import treeJuse from './images/tree_juse.webp'
import './App.scss';
import { FaCheck } from 'react-icons/fa'
import { MdLocationPin, MdPhone } from 'react-icons/md'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import queue from './images/queue.webp'
import GoogleMapComponent from './components/GoogleMap.js'
import { useCallback, useState } from 'react';

function App() {
  const [contVisible, setContVisible] = useState(true)
  const ggwp = useCallback(() => setContVisible(!contVisible), [contVisible])

  return (
    <>
      <div className='banner d-flex flex-column align-items-center justify-content-xl-between'>
        <img src={logo} alt='Logo' className='logo mt-4 mt-xl-5' />
        <div className='d-flex w-100 flex-column flex-xl-row justify-content-around align-items-center infoBanner'>
          <h1 className='header'>Вичавлення соку<br /> з ваших фруктів<br />та овочів</h1>
          <div className='priceTable'>
            <ul className='banner-list'>
              <li className='banner-content__price'><FaCheck className='d-none d-md-inline' />Без миття фруктів - 10 грн/літр</li>
              <li className='banner-content__price'><FaCheck className='d-none d-md-inline' />З миттям фруктів - 11 грн/літр</li>
              <li className='banner-content__price'><FaCheck className='d-none d-md-inline' />Cік, пастеризація з миттям - 80грн/3л, 130грн/5л</li>
              <li className='banner-content__price'><FaCheck className='d-none d-md-inline' />Коробка для соку - 25грн/3л, 35грн/5л</li>
              <li className='banner-content__price'><FaCheck className='d-none d-md-inline' />Вичавлення винограду - 15 грн/літр</li>
              <li className='banner-content__price fw-bold'><FaCheck className='d-none d-md-inline' />Під час відключення світла <br/>працюємо від генератора!</li>
            </ul>
          </div>
        </div >
      </div >
      <Navbar expand="lg" className="mainNav p-0">
        <Container fluid className='p-0 justify-content-center justify-content-xl-between expandCont' style={{ top: contVisible ? '0' : '-400px' }}>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={ggwp} />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 w-100 justify-content-around font-sans"
              navbarScroll
            >
              <Nav.Link href="/">Головна</Nav.Link>
              <Nav.Link href="#benefits">Переваги</Nav.Link>
              <Nav.Link href="#contacts"> Контакти</Nav.Link>
              <div className="visible-xs align-items-center align-items-center contactBlock d-flex flex-column justify-content-around contactGroup">
                <Nav.Link className="pull-right" href="tel:+380673001938"><MdPhone className='locationIcon' /> +38 (067) 300 19 38</Nav.Link>
                <Nav.Link className="pull-right" href="#contacts"><MdLocationPin className='locationIcon mb-1' />м. Львів вул. Чижевського 84</Nav.Link>

              </div>
            </Nav>
          </Navbar.Collapse>
          {contVisible ? <Nav.Link className="mobilePhone font-sans ms-5 d-block d-lg-none fw-bold" href="tel:+380673001938"><MdPhone className='locationIcon' /> +38 (067) 300 19 38</Nav.Link> : null}
        </Container>
      </Navbar >
      <div className='d-flex flex-wrap align-items-center flex-md-row justify-content-center justify-content-lg-around benefitList' id='benefits'>
        <h4 className='col-auto'>Пастеризація соку</h4>
        <h4 className='col-auto'>200 літрів за 30хв</h4>
        <h4 className='col-auto'>Вичавлення винограду</h4>
        <h4 className='col-auto'>Миття яблук</h4>
        <h4 className='col-auto'>Безкоштовна оренда емностей</h4>
      </div>
      <section className='section mt-5' id='advantages'>
        <div className='benefitsContainer'>
          <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center'>
            <img alt='Packet' className="boxImg" src={packet} />
            <div className="col-md-offset-1 col-12 col-lg-6 px-5 p-lg-0">
              <div className='mb-5'>
                <div className='d-flex align-items-center mb-2'>
                  <img src={queue} alt='Queue' className='iconImg' />
                  <h2 className='ms-3 text-uppercase fw-bold'>Жива Черга</h2>
                </div>
                <h2>Ми швидко облуговуємо клієнтів, тому потреби у попередньому записі немає. Привозьте фрукти у зручний вам час, якщо у вас великий об'єм то подзвоніть нам і ми підкажемо коли краще приїхати.</h2>
              </div>
              <div className='mb-5'>
                <div className='d-flex align-items-center mb-2 '>
                  <img src={packetIcon} alt='Bag-in-Box' className='iconImg' />
                  <h2 className='ms-3 text-uppercase fw-bold'>Пастеризація соку</h2>
                </div>
                <h2>Доступна легка пастеризація соку в пакети Bag–in–Box. Cік зберігає більше корисних властивостей та довше
                  простоїть після відкриття пакету ніж у банці.</h2>
              </div>
              <div className='mb-5'>
                <div className='d-flex align-items-center'>
                  <img src={priceTag} alt='Price' className='iconImg' />
                  <h2 className='ms-3 text-uppercase fw-bold'>Кращі ціни</h2>
                </div>
                <h2>У нас найдешевші ціни у Львові. Крім того потужне гідравлічне обладнання вичавить з ваших фруктів більше
                  соку!</h2>
              </div>
            </div>
          </div>
          <hr className='separator60' />
          <div className='proposals px-5 p-lg-0'>
            <div className='d-flex flex-column flex-md-row justify-content-between my-5'>
              <div className='col-12 col-md-7 justify-content-center d-flex flex-column'>
                <h2 className='text-uppercase fw-bold'>Купимо ваші яблука</h2>
                <h2>Купимо яблука за вигідною ціною, можливі великі об'єми. Всі деталі за вказаними телефонами.</h2>
              </div>
              <div className="d-flex justify-content-center justify-content-md-start col-12 col-md-5 col-md-offset-1">
                <img alt='Apple' className='img-responsive' src={apple} />
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between my-5">
              <div className='col-12 col-md-6 justify-content-center d-flex flex-column'>
                <h2 className='text-uppercase fw-bold'>Приймаємо замовлення на березовий сік</h2>
                <h2>Весною планується заготовка та пастеризація березового соку. Для попереднього замовлення дзвоніть за
                  вказаними телефонами.</h2>
              </div>
              <div className="d-flex justify-content-center justify-content-md-start col-12 col-md-5 col-md-offset-1">
                <img alt="" className="img-responsive propositions__tree-juce-img" src={treeJuse} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5' id='contacts'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-5 d-flex align-self-center justify-content-center justify-content-lg-between mb-5 mb-lg-0'>
              <div className='d-flex flex-column align-items-center font-sans'>
                <h1>КОНТАКТИ</h1>
                <h2 className='text-center'>
                  м. Львів вул. Чижевського 84<br />
                  <a href="tel:+380673001938">+38 067 300 19 38</a><br />
                  Понеділок - Субота<br />

                  8:00 - 20:00
                </h2>
              </div>
            </div>
            <div className='col-12 col-lg-7'>
              <GoogleMapComponent />
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

export default App;
